import moment from "moment";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import {
  createInfoCell,
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";

export interface NotificationData {
  id: string;
  notificationDate: string;
  notificationType: string;
  triggeringUserId: {
    username: string;
  };
  creatorUserId: {
    username: string;
  };
}

export const createNotificationsRows = (
  data: Array<NotificationData>
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry: NotificationData) => {
    const row: TableRowProps = createRow(
      createNumberCell(entry),
      createCreatedAtCell(entry),
      createNotificationTypeCell(entry),
      createUserCell(entry),
      createCreatorCell(entry)
    );
    rows.push(row);
  });
  return rows;
};

const capitalize = (str: string): string => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
}

const createNumberCell = (entry: NotificationData) => {
  return createInfoCell(entry.id);
};

const createCreatedAtCell = (entry: NotificationData) => {
  return createInfoCell(moment(entry.notificationDate).format("yyyy-MM-DD"));
};

const createNotificationTypeCell = (entry: NotificationData) => {
  return createInfoCell(entry.notificationType);
};

const createUserCell = (entry: NotificationData) => {
    return createInfoCell(capitalize(entry.triggeringUserId.username));
  };

  const createCreatorCell = (entry: NotificationData) => {
    const username = entry.creatorUserId?.username;
    return createInfoCell(username ? capitalize(username) : 'Unknown Creator');
  };