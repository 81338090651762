export const isEmail = (val: string) => {
  const emailRegex = /^[a-zA-Z0-9-.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  return emailRegex.test(val) ? true : "Please enter a valid email";
};

export const isAccountNumber = (val: string) => {
  if (!val) return true;
  const accountNumberRegex = /^[0-9]{4}-[0-9]+$/;
  return accountNumberRegex.test(val) ? true : "Wrong Format";
};

export const isPhoneNumber = (val: string) => {
  const phoneRegex = /^[0-9]{3}-*[0-9]{3}-*[0-9]{4}$/;
  return phoneRegex.test(val) ? true : "Number should be 10 digits";
};
